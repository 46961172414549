AFRAME.registerComponent("store-item", {
    schema: {
        itemId: {type: "string"},
        borderPosition: {type: "vec3", default: {x: 0, y: 0, z: 0}},
        border_borderHalfSize: {type: "vec2", default: {x: 0.6, y: 0.6}},

        border_lineBaseWidth: {default: 0.02},
        border_edgeLineBaseWidth: {default: 0.024},

        border_cornerRadius: {default: 0.05},
        /** distance between curve start and bezier control points */
        border_cornerControlPointDistance: {default: 0.55228},
        border_cornerSize: {default: 0.4},
        border_cornerNumPoints: {default: 4},

        /** update line width only when difference > `updateDeltaWidth` */
        border_updateDeltaWidth: {default: 0.001},

        border_color: {type: "color", default: "#FFF"},
        border_edgeColor: {type: "color", default: "#CCC"},
        border_activeColor: {type: "color", default: "#3F9CFF"},

        border_activeInDuration: {default: "200"},
        border_activeOutDuration: {default: "200"},

        boundingBoxPosition: {type: "vec3", default: {x: 0, y: 0, z: 0}},
        boundingBoxSize: {type: "vec3", default: {x: 1, y: 1, z: 1}},
        boundingBoxVisible: {type: "boolean", default: false},

        itemPointAudio: {type: "string"},
        itemSelectAudio: {type: "string"},

        uiPanelEl: {type: "selector"},
    },

    init: function () {
        this.bindMethods();

        this.el.querySelector(".bbmodel").addEventListener("model-loaded", this.onModelLoad);

        // this.el.addEventListener("model-loaded", this.onModelLoad);

        this.createSoundEl();
    },

    update: function (oldData) {
        const modifiedData = AFRAME.utils.diff(oldData, this.data);

        const borderData = this.borderDataFromData(modifiedData);

        if (Object.keys(borderData.length > 0))
            this.borderEl?.setAttribute("item-border", borderData);

        for (const modifiedKey in modifiedData) {
            switch (modifiedKey) {
                case "borderPosition":
                    this.borderEl?.object3D.position.copy(this.data.borderPosition);

                case "boundingBoxPosition":
                    this.boundingBoxEl?.object3D.position.copy(this.data.boundingBoxPosition);
                    break;

                case "boundingBoxSize":
                    this.boundingBoxEl?.setAttribute("geometry", {
                        width: this.data.boundingBoxSize.x,
                        depth: this.data.boundingBoxSize.y,
                        height: this.data.boundingBoxSize.z,
                    });
                    break;

                case "boundingBoxVisible":
                    this.boundingBoxEl?.setAttribute("visible", this.data.boundingBoxVisible);
                    break;
            }
        }
    },

    tick: function () {},

    onModelLoad: function (e) {
        var modelEl = e.target;
        var obj = modelEl.getObject3D("mesh");
        var bbox = new THREE.Box3().setFromObject(obj);

        var globalCenter = new THREE.Vector3();
        var globalSize = new THREE.Vector3();

        bbox.getCenter(globalCenter);
        bbox.getSize(globalSize);

        globalSize.max(new THREE.Vector3(1, 1, 1));
        globalSize.multiplyScalar(1.2);

        var center = this.el.object3D.worldToLocal(globalCenter);
        var size = this.el.object3D.worldToLocal(globalSize);

        this.createBorderEl(center, size);
        this.createBoundingBoxEl(center, size);

        this.boundingBoxEl.addEventListener("raycaster-intersected", (evt) => {
            this.borderEl.emit("activeIn");

            this.itemPointSoundEl.components.sound.playSound();
        });

        this.boundingBoxEl.addEventListener("raycaster-intersected-cleared", (evt) => {
            this.borderEl.emit("activeOut");
        });

        this.boundingBoxEl.addEventListener("click", (evt) => {
            this.data.uiPanelEl.emit("fadeIn");
            this.data.uiPanelEl.setAttribute("visible", true);
            this.el.emit("select", {itemId: this.data.itemId}, false);

            enableUiHitboxes(this.data.uiPanelEl);
        });
    },

    createBorderEl: function (center, size) {
        const borderEl = document.createElement("a-entity");

        borderEl.object3D.position.copy(center);
        borderEl.setAttribute("item-border", this.borderDataFromData(this.data));

        var width = (size.x + size.z) / 2.0;
        var height = size.y;
        borderEl.setAttribute("item-border", {
            borderHalfSize: new THREE.Vector2(width / 2.0, height / 2.0),
        });

        borderEl.setAttribute("animation__activein", {
            startEvents: "activeIn",
            property: "item-border.color",
            type: "color",
            to: this.data.border_activeColor,
            dur: this.data.border_activeOutDuration,
        });

        borderEl.setAttribute("animation__activeout", {
            startEvents: "activeOut",
            property: "item-border.color",
            type: "color",
            to: this.data.border_color,
            dur: this.data.border_activeOutDuration,
        });

        borderEl.setAttribute("animation__opacityfull", {
            startEvents: "opacityFull",
            property: "item-border.opacity",
            to: 1,
            dur: 300,
        });
        borderEl.setAttribute("animation__opacitysemi", {
            startEvents: "opacitySemi",
            property: "item-border.opacity",
            to: 0.3,
            dur: 300,
        });
        borderEl.setAttribute("animation__opacityzero", {
            startEvents: "opacityZero",
            property: "item-border.opacity",
            to: 0,
            dur: 300,
        });

        this.borderEl = this.el.appendChild(borderEl);
    },

    createBoundingBoxEl: function (center, size) {
        const boundingBoxEl = document.createElement("a-box");

        boundingBoxEl.object3D.position.copy(center);

        boundingBoxEl.setAttribute("visible", this.data.boundingBoxVisible);
        boundingBoxEl.setAttribute("geometry", {
            width: size.x,
            depth: size.z,
            height: size.y,
        });
        boundingBoxEl.classList.add("item-bb");
        this.boundingBoxEl = this.el.appendChild(boundingBoxEl);
    },

    createSoundEl: function () {
        const itemPointSoundEl = document.createElement("a-entity");
        itemPointSoundEl.setAttribute("sound", {
            src: this.data.itemPointAudio,
            rolloffFactor: 0,
            autoplay: false,
        });

        const selectItemSoundEl = document.createElement("a-entity");
        selectItemSoundEl.setAttribute("sound", {
            src: this.data.itemSelectAudio,
            rolloffFactor: 0,
            autoplay: false,
        });

        selectItemSoundEl.classList.add("selected-item-sound");

        this.itemPointSoundEl = this.el.appendChild(itemPointSoundEl);
        this.selectItemSoundEl = this.el.appendChild(selectItemSoundEl);
    },

    /**
     * filter data properties starting with "border_" and create data ready be sent to `item-border`
     *
     * @param {*} storeItemData data from `store-item`
     * @returns data ready for `item-border`
     */
    borderDataFromData(storeItemData) {
        return Object.keys(storeItemData)
            .filter((key) => key.startsWith("border_"))
            .reduce((obj, key) => {
                obj[key.slice("border_".length)] = storeItemData[key];
                return obj;
            }, {});
    },

    /**
     * bind methods used in callbacks
     */
    bindMethods: function () {
        this.onModelLoad = this.onModelLoad.bind(this);
    },
});
